<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Noviembre 2023                                                  ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <v-main style="padding: 0 1rem !important;">

        <!-- filtros de busqueda y boton para registrar una prestador -->
        <div class="d-flex pt-3">
            <v-text-field class="me-2" outlined dense hide-details label="Código habilitación" @paste.prevent
                v-model="buscarCodigoPrestador" @keypress="validarLetra" maxlength="12" @input="listarPrestadores()">
            </v-text-field>

            <v-text-field class="filtros me-2" outlined dense hide-details label="Nombre" @paste.prevent
                v-model="buscadorNombrePrestador" @keypress="validarSoloLetra" @input="listarPrestadores()">
            </v-text-field>

            <v-text-field class="me-2" outlined dense hide-details label="NIT" @paste.prevent
                v-model="buscadorNit" @keypress="validarLetra" @input="listarPrestadores()">
            </v-text-field>

            <v-tooltip left color="success">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn small class="ms-2" fab color="success" v-bind="attrs" v-on="on" 
                        @mousedown.prevent="dialog = true">
                        <v-icon> add </v-icon>
                    </v-btn>
                </template>
                <span>Agregar</span>
            </v-tooltip>
        </div>

        <!-- dialogo para crear y editar un prestador -->
        <v-dialog v-model="dialog" persistent transition="dialog-bottom-transition" max-width="39rem">
            <v-card>
                <v-card-title class="fondoDialog">
                    <span class="text-h6">{{ tituloDialog }}</span>
                </v-card-title>

                <!-- formulario para crear y editar un prestador -->
                <v-card-text style="padding-top: 0.7rem;">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <v-form class="mt-3" lazy-validation>
                            <!-- Campo para el nombre del prestador -->
                            <div class="d-flex">
                               <validation-provider v-slot="{ errors }" :rules="{ required: true, }">
                                    <v-text-field outlined id="nombrePrestador" v-model="registrarPrestador.nombrePrestador" :error-messages="errors"
                                        dense style="width: 35.9rem" label="Nombre" @input="convertirAMayusculas('nombrePrestador')">
                                    </v-text-field>
                                </validation-provider>
                            </div>
                            <div class="d-flex">
                                <!-- Campo para el Código de habilitación del prestador -->
                                <v-tooltip right color="#1867c0" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <validation-provider v-slot="{ errors }" :rules="{ required: true, numeric: true, min: 9, ceroCH: true }" class="me-2">
                                            <v-text-field id="codigoHabilitacion" v-model="registrarPrestador.codigoHabilitacion" dense outlined @paste.prevent
                                                maxlength="13" :error-messages="validarExistenciaDelCodigo || errors" style="width: 17.7rem" :disabled="disabled == 1" v-bind="attrs"
                                                v-on="on" label="Código habilitación" @keypress="validarLetra" @input="validarCodigoHabilitacion()">
                                            </v-text-field>
                                        </validation-provider>
                                    </template>
                                    <span>
                                        Código de habilitación del prestador.
                                        <br>Está compuesto mínimo por nueve dígitos.
                                        <br><em>Ejemplo: 123456789</em>
                                    </span>
                                </v-tooltip>
                                <!-- Campo para el NIT (Número de Identificación Tributaria) del prestador -->
                                <v-tooltip right color="#1867c0">
                                    <template v-slot:activator="{ on, attrs }">
                                        <validation-provider v-slot="{ errors }" :rules="{ required: true, numeric: true, min: 9, ceroCH: true }">
                                            <v-text-field outlined id="nit" v-model="registrarPrestador.nit" dense :error-messages="errors"
                                                maxlength="9" style="width: 17.7rem" :disabled="disabled == 1" @paste.prevent
                                                label="NIT" @keypress="validarLetra" v-bind="attrs" v-on="on">
                                            </v-text-field>
                                        </validation-provider>
                                    </template>
                                    <span>Número de identificación tributaria del cliente.
                                        <br>Está compuesto por nueve dígitos.
                                        <br><em>Ejemplo: 890985122</em>
                                    </span>
                                </v-tooltip>
                            </div>
                            <div class="d-flex">
                                <!-- Campo para el código detalle cliente del prestador -->
                                <validation-provider v-slot="{ errors }" :rules="{ required: true, numeric: true, allZero: true }" class="me-2">
                                    <v-text-field outlined id="detalleCliente" v-model="registrarPrestador.detalleCliente" 
                                        :error-messages="errors" dense style="width: 11.6rem" label="Detalle cliente"  
                                        @keypress="validarLetra" :disabled="disabled == 1" maxlength="3" @paste.prevent>
                                    </v-text-field>
                                </validation-provider>
                                <!-- Campo para el código del nivel del prestador -->
                                <validation-provider v-slot="{ errors }" class="me-2">
                                    <v-select id="nivel" v-model="registrarPrestador.nivel" :items="tiposDeNiveles" label="Nivel" :error-messages="errors"
                                        style="width: 11.6rem" dense outlined :menu-props="{offsetY:true}" :disabled="disabled == 1">
                                    </v-select>
                                </validation-provider>
                                <!-- Campo para la naturaleza del prestador -->
                                <validation-provider v-slot="{ errors }" :rules="{ required: true }">
                                    <v-select id="naturaleza" v-model="registrarPrestador.naturaleza" :items="tiposDeNaturaleza" label="Naturaleza" :error-messages="errors"
                                        style="width: 11.6rem" dense outlined :menu-props="{offsetY:true}" :disabled="disabled == 1">
                                    </v-select>
                                </validation-provider>
                            </div>
                            <div class="d-flex">
                                <!-- Campo para el pais donde se ubica el prestador -->
                                <validation-provider v-slot="{ errors }" :rules="{ required: true }" class="me-2">
                                    <v-autocomplete id="pais" v-model="registrarPrestador.pais" label="País" dense outlined :error-messages="errors" :items="opcionesPais"
                                        @change="onPaisChange" style="width: 11.6rem" :menu-props="{ maxHeight: 170, offsetY: true, maxWidth: 185.59}" :disabled="disabled == 1">
                                    </v-autocomplete>
                                </validation-provider>
                                <!-- Campo para el departamento donde se ubica el prestador -->
                                <validation-provider v-slot="{ errors }" :rules="{ required: true }" class="me-2">
                                    <v-autocomplete id="departamento" v-model="registrarPrestador.departamento" label="Departamento" dense outlined :error-messages="departamentoPresente || errors" 
                                        :items="opcionesDepartamento" @click="listarDepartamento()" style="width: 11.6rem" :menu-props="{ maxHeight: 170, offsetY: true, maxWidth: 185.59 }" 
                                        :disabled="registrarPrestador.pais === '' || disabled == 1">
                                    </v-autocomplete>
                                </validation-provider>
                                <!-- Campo para la municipio donde se ubica el prestador -->
                                <validation-provider v-slot="{ errors }" :rules="{ required: registrarPrestador.departamento !== '' && registrarPrestador.departamento !== '00' }">
                                    <v-autocomplete id="municipio" v-model="registrarPrestador.municipio" label="Municipio" dense outlined :error-messages="errors" :items="opcionesMunicipio" 
                                        @click="listarMunicipio()" style="width: 11.6rem" :menu-props="{ maxHeight: 170, offsetY: true, maxWidth: 185.59 }"
                                        :disabled="registrarPrestador.departamento === '' || registrarPrestador.departamento === '00' || disabled == 1">
                                    </v-autocomplete>
                                </validation-provider>
                            </div>
                            <!-- Campo para la dirección del prestador -->
                            <div class="d-flex">
                                <validation-provider v-slot="{ errors }" :rules="{ required: true }">
                                    <v-text-field id="direccion" v-model="registrarPrestador.direccion" :error-messages="errors" style="width: 35.9rem"
                                        outlined dense label="Dirección" @click="abrirDialogoDireccion()" readonly>
                                    </v-text-field>
                                </validation-provider>
                            </div>
                            <div class="d-flex">
                                <!-- Campo para el correo del prestador -->
                                <validation-provider v-slot="{ errors }" :rules="{ required: true, validEmail: true}" class="me-2">
                                    <v-text-field id="correo" v-model="registrarPrestador.email" dense outlined label="Correo"
                                        :error-messages="errors" style="width: 17.7rem">
                                    </v-text-field>
                                </validation-provider>
                                <!-- Campo para el número de contacto del prestador -->
                                <validation-provider v-slot="{ errors }" :rules="{ required: true }">
                                    <v-text-field id="numeroContacto" v-model="registrarPrestador.numeroContacto" :error-messages="errors"
                                        style="width: 17.7rem" outlined dense label="Número(s) de contacto" @click="abrirDialogoContacto()" readonly>
                                    </v-text-field>
                                </validation-provider>
                            </div>
                            <!-- Botones de cerrar, guardar y actualizar que se activan cuando se abre el dialogo para registrar un prestador en base de datos-->
                            <div class="d-flex justify-end">
                                <v-btn color="error" text @click="reset()">
                                    CERRAR
                                </v-btn>
                                <v-btn v-if="!editar" depressed @click="guardarPrestador()" :disabled="botonDeshabilitado || invalid" color="success">
                                    {{ textoBoton }}
                                </v-btn>
                                <v-btn v-else depressed @click="actualizarPrestador()" :disabled="invalid" color="success">
                                    {{ textoBoton }}
                                </v-btn>
                            </div>
                        </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Formulario para ingresar la dirección del prestador -->
        <v-dialog  v-model="dialogDireccion" persistent max-width="38rem">
            <v-card>
                <v-card-title class="bg-white">
                    <span>Dirección</span>
                </v-card-title>

                <v-card-text>
                    <validation-observer ref="observerDireccionUrbana" v-slot="{ invalid }">
                        <v-radio-group v-model="tiposDireccion" row>
                            <v-radio class="invisible-radio"></v-radio>
                            <v-radio label="Urbana" @click="direccionUrbana = true, direccionRural = false" value="urbana"></v-radio>
                            <v-radio label="Rural" @click="direccionRural = true, direccionUrbana = false" value="rural"></v-radio>
                        </v-radio-group>
                
                        <!-- form para direccion urbana -->
                        <v-form v-show="direccionUrbana" ref="formularioDireccionUrbana" class="mt-2">
                            <div class="d-flex">
                                <validation-provider v-slot="{ errors }" :rules="{ obligatorio: true }" style="width: 33.4%;">
                                    <v-autocomplete v-model="tipoVia" @change="direccionModal = true" :items="tiposVias" class="me-2"
                                        label="Tipo de vía" :error-messages="errors" outlined dense :menu-props="{offsetY:true, maxHeight: 155, maxWidth: 179.03}" maxlength="15">
                                    </v-autocomplete>
                                </validation-provider>
                                <validation-provider v-slot="{ errors }" :rules="{ obligatorio: true, allZero: true }" style="width: 33.3%;">
                                    <v-text-field v-model="numVia" @change="verDireccion = true" label="Ej: 32C" class="me-2"
                                        :error-messages="errors" outlined dense maxlength="5" @keypress="validarLetrasDireccion" @input="convertirTextoAMayusculas()">
                                    </v-text-field>
                                </validation-provider>
                                <v-autocomplete v-model="tipoOrientacionVia" @change="verOrientacionVia = true" :items="['NORTE', 'SUR', 'ESTE', 'OESTE']"
                                    style="width: 33.3%;" label="Ej: NORTE" outlined dense :menu-props="{offsetY:true, maxWidth: 186.5}" maxlength="5">
                                </v-autocomplete>
                            </div>
                            <div class="d-flex">
                                <validation-provider v-slot="{ errors }" :rules="{ obligatorio: true, allZero: true }" style="width: 33.4%;">
                                    <v-text-field v-model="letraNum" class="me-2" label="Ej: 45" :error-messages="errors" outlined dense maxlength="5" 
                                        @change="verletraNum = true" @input="convertirTextoAMayusculas()" @keypress="validarLetrasDireccion">
                                    </v-text-field>
                                </validation-provider>
                                <validation-provider v-slot="{ errors }" :rules="{ obligatorio: true, allZero: true }" style="width: 33.3%;" class="me-2">
                                    <v-text-field v-model="cuadrante" label="Ej: 21" :error-messages="errors" outlined dense maxlength="5" 
                                        @input="convertirTextoAMayusculas()" @keypress="validarLetrasDireccion">
                                    </v-text-field>
                                </validation-provider>
                                <v-autocomplete v-model="tipoOrientacion" @change="verOrientacion = true" :items="['NORTE', 'SUR', 'ESTE', 'OESTE']"
                                    style="width: 33.3%;" label="Ej: NORTE" outlined dense :menu-props="{offsetY:true, maxWidth: 186.5}"  maxlength="5">
                                </v-autocomplete>
                            </div>
                            <div class="d-flex">
                                <validation-provider v-slot="{ errors }" :rules="{ ceroAndLetter: true }" style="width: 50%;" class="me-2">
                                    <v-text-field v-model="informacionAdicional" @click="verInformacionAdicional = true" label="Información adicional" :error-messages="errors"
                                        outlined dense maxlength="70" @keypress="validarLetrasEnInformacionAdicional" @input="convertirTextoAMayusculas()">
                                    </v-text-field>
                                </validation-provider>
                                <validation-provider v-slot="{ errors }" :rules="{ ceroAndLetter: true }" style="width: 50%;">
                                    <v-text-field v-model="barrio" @click="verBarrio = true" label="Barrio" outlined dense maxlength="30" 
                                        @keypress="validarLetrasEnInformacionAdicional" @input="convertirTextoAMayusculas()" :error-messages="errors">
                                    </v-text-field>
                                </validation-provider>
                            </div>
                            <v-alert color="grey lighten-2" style="color: black; line-height: 1.1rem;">
                                <span v-if="tipoVia || numVia || tipoOrientacionVia || letraNum || cuadrante || tipoOrientacion || verInformacionAdicional || verBarrio">
                                    {{ tipoVia }} {{ numVia }}
                                    <span v-show="verOrientacionVia"></span>
                                    {{ tipoOrientacionVia }}
                                    <span v-show="verDireccion">#</span>{{letraNum}} <span v-show="verletraNum">-</span> {{cuadrante}} {{ tipoOrientacion }}
                                    <span v-if="verInformacionAdicional && informacionAdicional">,</span>
                                    {{ informacionAdicional }}
                                    <span v-if="verBarrio && barrio">- BARRIO:</span>
                                    {{ barrio }}
                                </span>
                                <span v-else>&nbsp;</span>
                            </v-alert>
                            <v-card-actions class="d-flex justify-end">
                                <v-btn text color="error" @click="resetDireccion()">Cerrar</v-btn>
                                <v-btn color="success" :disabled="!camposDireccionUrbanaLlenos || invalid" @click="guardarDireccionUrbana()"> Aceptar </v-btn>
                            </v-card-actions>
                        </v-form>
                    </validation-observer>

                    <validation-observer ref="observerDireccionRural" v-slot="{ invalid }">
                        <v-form v-show="direccionRural" ref="formularioDireccionRural">
                            <div class="d-flex">
                                <validation-provider v-slot="{ errors }" :rules="{ obligatorio: true }" style="width: 50%;">
                                    <v-select v-model="tipoVereda" @change="direccionModal = true" :items="['VEREDA', 'SECTOR']" class="me-2"
                                        label="Seleccione..." :error-messages="errors" outlined dense :menu-props="{offsetY:true}">
                                    </v-select>
                                </validation-provider>
                                <validation-provider v-slot="{ errors }" :rules="{ obligatorio: true }" style="width: 50%;">
                                    <v-text-field v-model="nombreVereda" label="Nombre" :error-messages="errors" outlined dense maxlength="30" 
                                        @keypress="validarLetrasEnInformacionAdicional" @input="convertirTextoAMayusculas()">
                                    </v-text-field>
                                </validation-provider>
                            </div>
                            <v-alert color="grey lighten-2" style="color: black; line-height: 0.8rem;">
                                <span v-if="tipoVereda || nombreVereda">
                                    {{ tipoVereda }} {{ nombreVereda }}
                                </span>
                                <span v-else>&nbsp;</span>
                            </v-alert>
                            <v-card-actions class="d-flex justify-end">
                                <v-btn text color="error" @click="resetDireccion()">Cerrar</v-btn>
                                <v-btn color="success" :disabled="!camposDireccionRuralLlenos || invalid" @click="guardarDireccionRural()"> Aceptar </v-btn>
                            </v-card-actions>
                        </v-form>
                    </validation-observer>
                </v-card-text>

            </v-card>
        </v-dialog>

        <!-- Formulario para los números de contacto -->
        <v-dialog v-model="dialogContacto" persistent transition="dialog-bottom-transition"  max-width="31.1rem">
            <v-card>
                <v-card-title class="bg-white">
                    <span>Número(s) de contacto</span>
                </v-card-title>
                <v-card-text style="padding-top: 0.7rem;">
                    <validation-observer ref="observerContacto" v-slot="{ invalid }">
                        <v-form class="mt-1" lazy-validation ref="formularioContacto">
                            <div class="d-flex">
                                <v-text-field class="invisible-radio"></v-text-field>
                                <!-- Campo para el primer número de contacto -->
                                <validation-provider v-slot="{ errors }" :rules="{ obligatorio: true, numeric: true, telefono: 10, ceros: 'ceros',
                                    regex: ('^(30|31|32|33|34|35|36|37|38|39)\\d{8}$|^(601|602|604|605|606|607|608)\\d{7}$')}" class="me-2">
                                    <v-text-field id="numeroUno" v-model="numeroContactoUno" dense outlined label="Contacto 1"
                                        :error-messages="errors" style="width: 8.3rem" maxlength="10" @keypress="validarLetra" @paste.prevent>
                                    </v-text-field>
                                </validation-provider>
                                <!-- Campo para la extensión del primer número de contacto -->
                                <validation-provider v-slot="{ errors }" :rules="{ numeric: true }" class="me-2">
                                    <v-text-field id="extensionUno" v-model="extensionUno" dense outlined label="EXT"
                                        :error-messages="errors" style="width: 5rem" maxlength="5" @keypress="validarLetra" @paste.prevent
                                        :disabled="numeroContactoUno === ''">
                                    </v-text-field>
                                </validation-provider>
                                 <!-- Campo para el segundo número de contacto -->
                                 <validation-provider v-slot="{ errors }" :rules="{ numeric: true, telefono: 10, ceros: 'ceros',
                                    regex: ('^(30|31|32|33|34|35|36|37|38|39)\\d{8}$|^(601|602|604|605|606|607|608)\\d{7}$')}" class="me-2">
                                    <v-text-field id="numeroDos" v-model="numeroContactoDos" dense outlined label="Contacto 2"
                                        :error-messages="errors" style="width: 8.3rem" maxlength="10" @keypress="validarLetra" @paste.prevent>
                                    </v-text-field>
                                </validation-provider>
                                 <!-- Campo para la extensión del segundo número de contacto -->
                                 <validation-provider v-slot="{ errors }" :rules="{ numeric: true }">
                                    <v-text-field id="extensionDos" v-model="extensionDos" dense outlined label="EXT"
                                        :error-messages="errors" style="width: 5rem" maxlength="5" @keypress="validarLetra" @paste.prevent
                                        :disabled="numeroContactoDos === ''">
                                    </v-text-field>
                                </validation-provider>
                            </div>
                            <v-alert color="grey lighten-2" style="color: black; line-height: 0.8rem;">
                                <span v-if="numeroContactoUno || extensionUno || numeroContactoDos || extensionDos">
                                    <span v-if="numeroContactoUno">{{ numeroContactoUno }}</span>
                                    <span v-if="numeroContactoUno && extensionUno"> EXT: </span>
                                    <span v-if="extensionUno">{{ extensionUno }}</span>
                                    <span v-if="numeroContactoDos && (numeroContactoUno || extensionUno)"> - </span>
                                    <span v-if="numeroContactoDos">{{ numeroContactoDos }}</span>
                                    <span v-if="numeroContactoDos && extensionDos"> EXT: </span>
                                    <span v-if="extensionDos">{{ extensionDos }}</span>
                                </span>
                                <span v-else>&nbsp;</span>
                            </v-alert>
                            <v-card-actions class="d-flex justify-end">
                                <v-btn text color="error" @click="resetContacto()">Cerrar</v-btn>
                                <v-btn color="success" :disabled="invalid" @click="guardarContacto()">Aceptar</v-btn>
                            </v-card-actions>
                        </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Tabla que muestra la lista de los prestadores registradas en base de datos -->
        <v-data-table :loading="loading" fixed-header :headers="headersPrestadores" :items="listaDePrestadores"
            :page.sync="pagina" :items-per-page.sync="itemsPorPagina" :server-items-length="totalPaginas" class="elevation mt-4"
            height="66vh" :footer-props="footerPropslistaPrestador">
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" v-bind:key="item.id">
                        <td>{{ item.codigoHabilitacion }}</td>
                        <td>{{ item.nombrePrestador }}</td>
                        <td>{{ item.nit }}</td>
                        <td class="text-center">{{ item.detalleCliente }}</td>
                        <td>
                            <v-icon :color="item.eliminado === false ? 'success' : 'error'"> 
                                {{ item.eliminado === false ? 'check_circle' : 'cancel' }}
                            </v-icon>
                            {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
                        </td>
                        <td class="text-center">
                            <v-icon color="orange" @click="editarPrestador(item)">border_color</v-icon>
                            <v-btn icon @click="abrirDialogoEstado(item)">
                                <v-icon :color="item.eliminado === false ? 'error' : 'success'">
                                    {{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}
                                </v-icon>
                            </v-btn>
                        </td>
                        <td class="text-center">
                            <v-icon medium color="blue" @click="detallesDelPrestador(item)">library_add</v-icon>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:footer.page-text="items">
                {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
        </v-data-table>

        <!-- dialogo para cambiar el estado -->
        <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="22.7rem" persistent>
            <v-card>
                <v-card-title class="fondoDialog">
                    <span class="text-h6">{{ mensajeEstado }}</span>
                </v-card-title>
                <v-card-text>
                    <div class="d-flex justify-end" style="padding-top: 1.3rem">
                        <v-btn color="error" text @click="dialogoCambiarEstado = false">No</v-btn>
                        <v-btn color="success" depressed text :disabled="desactivar" @click="cambiarEstado()">Si</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- dialogo para ver los detalles de un prestador -->
        <v-dialog class="home" v-model="dialogDetalles" persistent transition="dialog-bottom-transition" max-width="28rem">
            <v-card>
                <v-card-title class="fondoDialog">
                    <span class="text-h6">Detalles del prestador</span>
                </v-card-title>

                <v-card-text style="padding-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="12" align="left" class="ma-0 pa-0 letra">
                                <span class="font-weight-black">Nivel: </span>
                                <span v-if="registrarPrestador.nivel == null">Sin datos</span>
                                <span v-if="registrarPrestador.nivel == 1">1</span>
                                <span v-if="registrarPrestador.nivel == 2">2</span>
                                <span v-if="registrarPrestador.nivel == 3">3</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12" align="left" class="ma-0 pa-0 letra">
                                <span class="font-weight-black">Naturaleza: </span>
                                <span v-if="registrarPrestador.naturaleza == 1">Pública</span>
                                <span v-if="registrarPrestador.naturaleza == 2">privada</span>
                                <span v-if="registrarPrestador.naturaleza == 3">Mixta</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12" align="left" class="ma-0 pa-0 letra">
                                <span class="font-weight-black">Código de habilitación: </span>
                                <span>{{ registrarPrestador.codigoHabilitacion }}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12" align="left" class="ma-0 pa-0 letra">
                                <span class="font-weight-black">Dirección: </span>
                                <span>{{ registrarPrestador.direccion }} <br>{{ municipio !== '' ? municipio + ', ' : '' }}{{ departamento !== '' ? departamento + ' - ' : '' }}{{ pais }}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12" align="left" class="ma-0 pa-0 letra">
                                <span class="font-weight-black">Correo: </span>
                                <span>{{ registrarPrestador.email }}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12" align="left" class="ma-0 pa-0 letra">
                                <span class="font-weight-black">Número(s) de contacto: </span>
                                <span>{{ registrarPrestador.numeroContacto }}</span>
                            </v-col>
                        </v-row>
                    </v-container>
                    <div class="d-flex justify-end">
                        <v-btn color="error" text @click="reset()">
                        CERRAR
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-main>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { extend, ValidationObserver, ValidationProvider, } from 'vee-validate';
import { mapMutations, mapState } from 'vuex';
import { Role } from '@/router/role.js';
import { required, min, email, numeric, regex } from 'vee-validate/dist/rules';
//import Vue from "vue"

extend('required', {
    ...required,
    message: 'Este campo es requerido'
})
extend('obligatorio', {
    ...required,
    message: 'Campo obligatorio'
})
extend('min', {
    ...min,
    message: 'Este campo permite mínimo 9 dígitos '
})
extend('validEmail', {
    ...email,
    message: 'La dirección de correo debe ser válida'
})
extend('telefono', {
    ...min,
    message: 'Debe contener mínimo 10 dígitos '
})
extend('numeric', {
    ...numeric,
    message: 'Este campo solo permite números'
})
extend('regex', {
    ...regex,
    message: 'Formato incorrecto.',
})
extend('ceros', {
    validate: value => {
        const cerosConsecutivos = /0{7,}/.test(value);
        const cerosSeparados = (value.match(/0/g) || []).length > 6;
        return !(cerosConsecutivos || cerosSeparados);
    },
    message: 'Formato incorrecto.'
})
extend('ceroCH', {
  validate: value => {
    if (value[0] === '0') {
      return false;
    }
    if (!/\d*[1-9]\d*/.test(value)) {
      return false;
    }
    return true;
  },
  message: 'Formato incorrecto.'
})
extend('allZero', {
  validate: value => {
    if (!/\d*[1-9]\d*/.test(value)) {
      return false;
    }
    return true;
  },
  message: 'Formato incorrecto.'
})
extend('ceroAndLetter', {
  validate: value => {
    const soloCeros = /^\d+$/.test(value) && parseInt(value) === 0;
    const contieneLetras = /[a-zA-Z]/.test(value);
    if (!soloCeros || contieneLetras) {
        return true;
    }
    return false;
  },
  message: 'Formato incorrecto.'
})

export default {
    name: 'Prestador',
    components: {
        ValidationObserver,
        ValidationProvider,
    },

    data() {
        return {
            roles: Role,
            userRoles: {},
            usuario: '',
            buscarCodigoPrestador: '',
            buscadorNombrePrestador: '',
            buscadorNit: '',
            dialog: false,
            tituloDialog: 'Crear prestador',
            registrarPrestador: {
                codigoHabilitacion: '',
                nombrePrestador: '',
                detalleCliente: '',
                nit: '',
                direccion: '',
                nivel: '',
                naturaleza: '',
                email: '',
                numeroContacto: '',
                pais: '',
                departamento: '',
                municipio: '',
            },
            tiposDeNiveles: [
                { text: '1', value: 1 },
                { text: '2', value: 2 },
                { text: '3', value: 3 }
            ],
            tiposDeNaturaleza: [
                { text: 'Pública', value: 1 },
                { text: 'Privada', value: 2 },
                { text: 'Mixta', value: 3 }
            ],
            opcionesPais: [],
            opcionesDepartamento: [],
            opcionesMunicipio: [],
            departamentoPresente: '',
            eliminado: false,
            loading: false,
            headersPrestadores: [
                { text: "CÓDIGO HABILITACIÓN", sortable: false, width: "9rem" },
                { text: "NOMBRE", sortable: false, width: "17rem" },
                { text: "NIT", sortable: false, width: "9rem" },
                { text: "DETALLE CLIENTE", align: "center", sortable: false, width: "9rem" },
                { text: "ESTADO", align: "center", sortable: false, width: "7rem" },
                { text: "ACCIONES", align: "center", sortable: false, width: "6rem" },
                { text: "DETALLES", align: "center", sortable: false, width: "1rem" },
            ],
            listaDePrestadores: [],
            pagina: 1,
            itemsPorPagina: 10,
            totalPaginas: 0,
            footerPropslistaPrestador: {
                'items-per-page-options': [10, 20, 30, 40, 50],
                'items-per-page-text': 'Items por página:',
                'show-current-page': true,
                'show-first-last-page': true,
            },
            prestadorSeleccionado: {},
            dialogoCambiarEstado: false,
            mensajeEstado: '',
            textoBoton: "GUARDAR",
            editar: false,
            disabled: 0,
            validarExistenciaDelCodigo: '',
            dialogDireccion: false,
            tiposDireccion: '',
            verInformacionAdicional: false,
            verBarrio: false,
            verDireccion: false,
            verOrientacionVia: false,
            verletraNum: false,
            direccionUrbana: false,
            direccionRural: false,
            direccionModal: false,
            tipoVia: '',
            tipoVereda: '',
            nombreVereda: '',
            numVia: '',
            letraNum: '',
            informacionAdicional: '',
            cuadrante: '',
            tipoOrientacion: '',
            tipoOrientacionVia: '',
            barrio: '',
            tiposVias: ["AVENIDA CALLE", "AVENIDA CARRERA", "AUTOPISTA", "AVENIDA", "ANILLO VIAL", "CALLE", "CALLEJÓN", "CARRERA", "CARRETERA", "CIRCUNVALAR", "DIAGONAL", "TRANSVERSAL"],
            rules: {
                agregarRules: [
                    v => !!v || "Campo obligatorio",
                ],
            },
            camposDireccionUrbanaLlenos: false,
            camposDireccionRuralLlenos: false,
            dialogDetalles: false,
            pais: '',
            departamento: '',
            municipio: '',
            dialogContacto: false,
            numeroContactoUno: '',
            numeroContactoDos: '',
            extensionUno: '',
            extensionDos: '',
            desactivar: false,
        }
    },
    // Se inicializa la variable userRoles con los roles del usuario autenticado
    created() {
        this.userRoles = this.auth.roles;
        this.listarPrestadores();
    },
    computed: {
        ...mapState(["auth", "notify", "busy", "enterprise"]),
        botonDeshabilitado() {
            return this.validarExistenciaDelCodigo !== '' || this.departamentoPresente !== '' || this.desactivar;
        }
    },
    //Configuración de observadores (watchers) para realizar acciones cuando cambian ciertos valores en el componente.
    watch: {
        pagina: function () {
            this.listarPrestadores();
        },
        itemsPorPagina: function () {
            this.pagina = 1;
            this.listarPrestadores();
        },
        'buscarCodigoPrestador': function () {
            this.pagina = 1;
            this.listarPrestadores();
        },
        'buscadorNombrePrestador': function () {
            this.pagina = 1;
            this.listarPrestadores();
        },
        'buscadorNit': function () {
            this.pagina = 1;
            this.listarPrestadores();
        },
        tipoVia: 'validarCamposDireccionUrbana',
        numVia: 'validarCamposDireccionUrbana',
        letraNum: 'validarCamposDireccionUrbana',
        cuadrante: 'validarCamposDireccionUrbana',
        tipoVereda: 'validarCamposDireccionRural',
        nombreVereda: 'validarCamposDireccionRural',
        'registrarPrestador.pais': {
            handler: 'onPaisChange',
            deep: true,
        },
        'registrarPrestador.departamento': {
            handler: 'onDepartamentoChange',
            deep: true,
        },
        dialog: function (newValue) {
            if (newValue && !this.prestadorSeleccionado.idPrestador) {
                this.listarPais();
            }
        },
        'registrarPrestador.codigoHabilitacion': function () {
            this.validarCodigoHabilitacion();
        },
        'dialogoCambiarEstado': function (nextValue) {
            if (nextValue === false) {
                setTimeout(() => {
                    this.desactivar = false;
                }, 500)
            }
        }
    },
    mounted() {
        this.usuario = this.auth.username.toUpperCase();
    },
    methods: {
        ...mapMutations(["updateAuth", "hideNotify", "showSuccess", "showBusy", "hideBusy", "showError"]),
        /**
         * Obtiene la lista de prestadores registrados mediante una solicitud HTTP GET al servidor.
         * Los registros se obtienen con paginación y se filtran según los parámetros especificados.
         * Se actualiza el número total de páginas.
         * Además, se controla el estado de carga durante la solicitud.
         */
        listarPrestadores() {
            this.buscadorNombrePrestador = this.buscadorNombrePrestador.toUpperCase();
            this.loading = true;
            this.$http.get(`msa-administration/prestador/listar`, {
                params: {
                    page: this.pagina - 1,
                    size: this.itemsPorPagina,
                    idEmpresa: this.enterprise.code,
                    codigoHabilitacion: `${this.buscarCodigoPrestador}`,
                    nombrePrestador: `${this.buscadorNombrePrestador.toLowerCase()}`,
                    nit: `${this.buscadorNit.toLowerCase()}`,
                },
            }).then( response => {
                this.listaDePrestadores = response.data.content;
                this.totalPaginas = response.data.totalElements;
                this.loading = false;
            }).catch(error => {
                console.log(error)
                this.loading = false;
            })
        },
        /**
         * Abre el diálogo de dirección y realiza las siguientes acciones:
         * - Verifica si hay una dirección ya establecida en el campo this.registrarPrestador.direccion
         * - Verifica si la dirección proporcionada es urbana o rural.
         * - Si es urbana, procesa la información de la dirección urbana.
         * - Si es rural, procesa la información de la dirección rural.
         * - Actualiza las variables de estado para mostrar u ocultar elementos en el diálogo.
         */
        abrirDialogoDireccion(){
            // Verifica si se proporcionó una dirección
            if (this.registrarPrestador.direccion !== '') {
                const esDireccionUrbana = this.tiposVias.some(opcion => this.registrarPrestador.direccion.startsWith(opcion));
                this.direccionUrbana = esDireccionUrbana;
                this.direccionRural = !esDireccionUrbana;
                
                if (esDireccionUrbana) {
                    // Procesa la información de la dirección urbana
                    this.direccionUrbana = true;
                    this.tiposDireccion = 'urbana';
                    // Obtiene el tipo de vía de la dirección urbana
                    const tipoViaOption = this.tiposVias.find(opcion => this.registrarPrestador.direccion.startsWith(opcion));
                    const direccionSinTipoVia = this.registrarPrestador.direccion.substring(tipoViaOption.length).trim();
                    const partesDireccion = direccionSinTipoVia.split(/\s+/);
                    const indiceNumVia = partesDireccion.indexOf('#');
                    const indiceGuion = partesDireccion.indexOf('-');
                    let numVia = '';
                    let letraNum = '';
                    let cuadrante = '';
                    let tipoOrientacionVia = '';
                    let tipoOrientacion = '';
                    // Determina la estructura de la dirección según la posición del guion
                    if (indiceGuion === 3) {
                        numVia = indiceNumVia === -1 ? partesDireccion[indiceNumVia + 1] : '';
                        letraNum = partesDireccion.slice(indiceGuion - 1, indiceGuion + 1).join(' ').trim().replace(/[#-]/g, '');
                        tipoOrientacionVia = indiceGuion === 3 ? partesDireccion[indiceNumVia + 2] : '';
                        tipoOrientacion = indiceGuion === 3 ? partesDireccion[indiceGuion + 2] : '';
                        cuadrante = indiceGuion === 3 ? partesDireccion[indiceGuion + 1] : '';
                    } else {
                        numVia = indiceNumVia === -1 ? partesDireccion[indiceNumVia + 1] : '';
                        letraNum = indiceGuion === 2 ? partesDireccion[indiceGuion -1].replace(/#/g, '') : ''; 
                        tipoOrientacionVia = indiceGuion === 2 ? '' : '';
                        tipoOrientacion = indiceGuion === 2 ? partesDireccion[indiceGuion + 2] : '';
                        cuadrante = indiceGuion === 2 ? partesDireccion[indiceGuion + 1] : '';
                    }
                    // Limpia el cuadrante si termina con una coma
                    cuadrante = cuadrante.endsWith(',') ? cuadrante.slice(0, -1) : cuadrante;
                    // Obtiene el resto de la dirección y actualiza las variables
                    let restoDireccionArray = partesDireccion.slice(indiceGuion + 2);
                    this.tipoVia = tipoViaOption || '';
                    this.numVia = numVia || '';
                    this.letraNum = letraNum || '';
                    this.cuadrante = cuadrante || '';
                    this.tipoOrientacionVia = tipoOrientacionVia || '';
                    this.verDireccion = true;
                    this.verletraNum = true;
                    this.verInformacionAdicional = true;
                    // Procesa la información adicional y el barrio si existen
                    if (restoDireccionArray.some(item => item.includes(',') || item.includes('-'))) {
                        const orientacionPalabras = ['NORTE', 'SUR', 'ESTE', 'OESTE'];
                        const orientacionEncontrada = orientacionPalabras.find(orientacion =>
                            restoDireccionArray.some(item => item.includes(orientacion))
                        );
                        if (orientacionEncontrada) {
                            this.tipoOrientacion = orientacionEncontrada;
                            restoDireccionArray = restoDireccionArray.filter(item => !item.toLowerCase().includes(orientacionEncontrada.toLowerCase()));
                        }
                        const indiceBarrio = restoDireccionArray.indexOf('-');
                        if (indiceBarrio !== -1) {
                            this.verBarrio = true;
                            this.barrio = restoDireccionArray.slice(indiceBarrio + 1).join(' ').replace(/^BARRIO:\s*/i, '');
                            restoDireccionArray = restoDireccionArray.slice(0, indiceBarrio);
                        }
                        this.informacionAdicional = restoDireccionArray.join(' ') || '';
                    } else {
                        // Si no hay información adicional o barrio, limpia las variables correspondientes
                        this.tipoOrientacion = tipoOrientacion || '';
                        this.informacionAdicional = restoDireccionArray.join(' ') || '';
                        this.barrio = '';
                    }
                } else {
                    // Procesa la información de la dirección rural
                    this.direccionRural = true;
                    this.tiposDireccion = 'rural';
                    // Obtiene el tipo y nombre de la vereda
                    const [, tipoVereda, nombreVereda] = this.registrarPrestador.direccion.match(/(\S+)\s+(.*)/);
                    this.tipoVereda = tipoVereda || '';
                    this.nombreVereda = nombreVereda || '';
                } 
                // Abre el diálogo de dirección
                this.dialogDireccion = true
            } else {
                // Si no se proporcionó una dirección, limpia las variables y abre el diálogo
                this.tipoVia = '';
                this.tipoVereda = '';
                this.nombreVereda = '';
                this.numVia = '';
                this.letraNum = '';
                this.tipoOrientacionVia = '';
                this.tipoOrientacion = '';
                this.informacionAdicional = '';
                this.cuadrante = '';
                this.barrio = '';
                this.dialogDireccion = true;
                this.direccionUrbana = true;
                this.tiposDireccion = 'urbana';
            }
        },
        /**
         * Valida si los campos obligatorios de una dirección urbana están llenos.
         * Actualiza la variable de estado `camposDireccionUrbanaLlenos` en consecuencia.
         */
        validarCamposDireccionUrbana() {
            this.camposDireccionUrbanaLlenos = this.tipoVia && this.numVia && this.letraNum && this.cuadrante;
        },
        /**
         * Valida si los campos obligatorios de una dirección rural están llenos.
         * Actualiza la variable de estado `camposDireccionRuralLlenos` en consecuencia.
         */
        validarCamposDireccionRural() {
            this.camposDireccionRuralLlenos = this.tipoVereda && this.nombreVereda;
        },
        /**
         * Guarda la dirección urbana ingresada por el usuario.
         * Limpia el campo de dirección y actualiza el modelo si el formulario es válido.
         * Cierra el diálogo de dirección urbana.
         */
        guardarDireccionUrbana() {
            this.registrarPrestador.direccion = '';
            if (this.$refs.formularioDireccionUrbana.validate()) {
                // Construir la dirección con los componentes ingresados
                const dir = `${this.tipoVia} ${this.numVia != '' && this.tipoOrientacionVia != '' && this.tipoOrientacionVia != null ? this.numVia + " " + this.tipoOrientacionVia + " #" : this.numVia + " #" }${this.letraNum != '' ? this.letraNum + " -" : ''} ${this.cuadrante != '' && this.tipoOrientacion != '' && this.tipoOrientacion != null ? this.cuadrante + " " + this.tipoOrientacion : this.cuadrante }${this.informacionAdicional != '' ? ", " + this.informacionAdicional : ''}${this.barrio != '' ? " - BARRIO: " + this.barrio: ''}`;
                // Actualizar el modelo con la nueva dirección
                this.registrarPrestador.direccion = dir;
                this.dialogDireccion = false;
                this.tiposDireccion = 'urbana';
            }
            this.resetDireccion();
        },
        /**
         * Guarda la dirección rural ingresada por el usuario.
         * Limpia el campo de dirección y actualiza el modelo si el formulario es válido.
         * Cierra el diálogo de dirección rural.
         */
        guardarDireccionRural() {
            this.registrarPrestador.direccion = '';
            if (this.$refs.formularioDireccionRural.validate()) {
                // Construir la dirección con los componentes ingresados
                const dir = `${this.tipoVereda} ${this.nombreVereda}`;
                // Actualizar el modelo con la nueva dirección
                this.registrarPrestador.direccion = dir;
                this.dialogDireccion = false;
                this.tiposDireccion = 'rural';
            }
            this.resetDireccion();
        },
        /**
         * Valida que la tecla presionada sea un número.
         * Evita la entrada de caracteres no numéricos.
         * @param {Event} event - Evento de tecla presionada.
         */
        validarLetra(event) {
            const teclaPresionada = event.key
            if (teclaPresionada.match(/[^0-9]/)) {
                event.preventDefault();
            }
        },
        /**
         * Valida que la tecla presionada sea una letra o espacio.
         * Evita la entrada de caracteres no alfabéticos.
         * @param {Event} event - Evento de tecla presionada.
         */
        validarSoloLetra(event) {
            const teclaPresionada = event.key
            if (teclaPresionada.match(/[^A-Za-záéíóúÁÉÍÓÚ. ]/)) {
                event.preventDefault();
            }
        },
        /**
         * Valida que la tecla presionada sea una letra o número.
         * Evita la entrada de caracteres no alfanuméricos.
         * @param {Event} event - Evento de tecla presionada.
         */
        validarLetrasDireccion(event) {
            const teclaPresionada = event.key
            if (teclaPresionada.match(/[^0-9A-Za-záéíóúÁÉÍÓÚ]/)) {
                event.preventDefault();
            }
        },
        /**
         * Valida que la tecla presionada sea una letra, número o caracteres especiales permitidos en información adicional.
         * Evita la entrada de caracteres no permitidos.
         * @param {Event} event - Evento de tecla presionada.
         */
        validarLetrasEnInformacionAdicional(event) {
            const teclaPresionada = event.key
            if (teclaPresionada.match(/[^0-9A-Za-záéíóúÁÉÍÓÚ.\- ]/)) {
                event.preventDefault();
            }
        },
        /**
         * Convierte el valor del campo especificado a mayúsculas en el objeto 'registrarPrestador'.
         * @param {string} campo - Nombre del campo a convertir.
         */
        convertirAMayusculas(campo) {
            this.registrarPrestador[campo] = this.registrarPrestador[campo].toUpperCase();
        },
        /**
         * Convierte varios campos relacionados con la dirección a mayúsculas.
         * Se utilizan los campos 'numVia', 'letraNum', 'cuadrante', 'informacionAdicional', 'barrio' y 'nombreVereda'.
         */
        convertirTextoAMayusculas() {
            this.numVia = this.numVia.toUpperCase();
            this.letraNum = this.letraNum.toUpperCase();
            this.cuadrante = this.cuadrante.toUpperCase();
            this.informacionAdicional = this.informacionAdicional.toUpperCase();
            this.barrio = this.barrio.toUpperCase();
            this.nombreVereda = this.nombreVereda.toUpperCase();
        },
        /**
         * Obtiene la lista de países y actualiza las opciones de país en el componente.
         * Las opciones se almacenan en el array 'opcionesPais'.
         * Asigna el valor por defecto "COLOMBIA" al this.registrarPrestador.pais.
         */
        async listarPais() {
            this.opcionesPais = [];
            try {
                const response = await this.$http.get(`msa-administration/pais/listaDePaises`)
                this.opcionesPais = response.data.map(item => ({
                    text: `${item.pais}`,
                    value: `${item.idPais}`,
                }));
                if (this.opcionesPais.length > 0 && this.registrarPrestador.pais === "") {
                    this.registrarPrestador.pais = this.opcionesPais.find(item => item.text === 'COLOMBIA').value;
                }
            } catch (error) {
                console.log(error);
            }
        },
        /**
         * Método invocado cuando se produce un cambio en la selección del país.
         * Limpia la variable de departamento y llama al método para listar los departamentos basados en el país seleccionado.
         */
        async onPaisChange() {
            // Limpiar la variable de departamento y el municipio al cambiar el país
            if (!this.prestadorSeleccionado.idPrestador) {

                this.registrarPrestador.departamento = '';
                // Llamar al método para listar departamentos
                await this.listarDepartamento();
            }
        },
        /**
         * Obtiene la lista de departamentos basada en el país seleccionado y actualiza las opciones de departamento en el componente.
         * Las opciones se almacenan en el array 'opcionesDepartamento'.
         */
        async listarDepartamento() {
            if (this.registrarPrestador.pais !== '' && this.registrarPrestador.pais !== undefined && this.registrarPrestador.pais !== null) {
                try {
                    const response = await this.$http.get(`msa-administration/departamento/listaDeDepartamentos`, {
                        params: {
                            idPais: this.registrarPrestador.pais
                        }
                    });
                    const filterDepa = response.data.filter(item => item.idDepartamento !== '00');
                    this.opcionesDepartamento = filterDepa.map(item => ({
                        text: `${item.departamento}`,
                        value: `${item.idDepartamento}`,
                    }));
                    if (this.opcionesDepartamento.length > 0) {
                        this.departamentoPresente = '';
                    } else {
                        this.departamentoPresente = 'Sin datos para seleccionar';
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        },
        /**
         * Método invocado cuando se produce un cambio en la selección del departamento.
         * Limpia la variable de municipio y llama al método para listar los municipios basados en el departamento seleccionado.
         */
        async onDepartamentoChange() {
            // Limpiar la variable de departamento al cambiar el país
            this.registrarPrestador.municipio = '';

            // Llamar al método para listar departamentos
            await this.listarMunicipio();
        },
        /**
         * Obtiene la lista de municipios basada en el departamento seleccionado y actualiza las opciones de municipio en el componente.
         * Las opciones se almacenan en el array 'opcionesMunicipio'.
         */
        async listarMunicipio() {
            if (this.registrarPrestador.departamento !== '') {
                try {
                    const response = await this.$http.get(`msa-administration/municipio/listaDeMunicipios`, {
                        params: {
                            idDepartamento: this.registrarPrestador.departamento
                        }
                    });
                    this.opcionesMunicipio = response.data.map(item => ({
                        text: `${item.ciudad}`,
                        value: `${item.idCiudad}`,
                    }));
                } catch (error) {
                    console.log(error);
                }
            }
        },
        /**
         * Valida la existencia de un código de habilitación consultando al servidor.
         * Si el código de habilitación existe, establece un mensaje de error y un estado de validación.
         * Si el código de habilitación no existe o está vacío, se restablece el mensaje y el estado de validación.
         */
        validarCodigoHabilitacion(){
            if (this.registrarPrestador.codigoHabilitacion !== '') {
                this.$http.get(`msa-administration/prestador/codigoHabilitacion`, {
                    params: {
                        codigoHabilitacion: this.registrarPrestador.codigoHabilitacion,
                        idEmpresa: this.enterprise.code
                    }
                })
                .then((response) => {
                    if (response.data !== "El codigo de habilitacion no existe") {
                        this.validarExistenciaDelCodigo = "El código de habilitación ya existe";
                    } else {
                        this.validarExistenciaDelCodigo = "";
                    }
                }).catch((error) => {
                    this.desactivar = true;
                    console.log(error);
                })
            } else {
                this.validarExistenciaDelCodigo = "";
            }
        },
        /**
         * Abre el diálogo de contacto y realiza las siguientes acciones:
         * - Verifica si hay un contacto ya establecid en el campo this.registrarPrestador.numeroContacto
         * - Verifica si el contacto tiene un guion.
         * - Si tiene un guion verifica si el contacto tiene la palabra EXT: y asigna los valores a cada campo.
         * - Si no tiene guion verifica si el contacto tiene la palabra EXT: y asigna los valores a cada campo.
         * - Actualiza las variables de estado para mostrar u ocultar elementos en el diálogo.
         */
        abrirDialogoContacto(){
            if (this.registrarPrestador.numeroContacto !== '') {
                const contactos = this.registrarPrestador.numeroContacto.split(" - ");
                if (contactos.length === 2) {
                    // Dos números de contacto
                    const [numeroContactoUno, extUno] = contactos[0].split(" EXT: ").map(value => value.trim());
                    const [numeroContactoDos, extDos] = contactos[1].split(" EXT: ").map(value => value.trim());
                    this.numeroContactoUno = numeroContactoUno;
                    this.numeroContactoDos = numeroContactoDos;
                    this.extensionUno = extUno || '';
                    this.extensionDos = extDos || '';
                } else if (contactos.length === 1) {
                    // Un número de contacto
                    const [numeroContacto, extension] = contactos[0].split(" EXT: ").map(value => value.trim());
                    this.numeroContactoUno = numeroContacto;
                    this.numeroContactoDos = '';
                    this.extensionDos = '';
                    if (extension !== undefined) {
                        // EXT: está presente
                        this.extensionUno = extension || '';
                    } else {
                        // No hay EXT:
                        this.extensionUno = '';
                    }
                }
            } else {
                this.numeroContactoUno = '';
                this.numeroContactoDos = '';
                this.extensionUno = '';
                this.extensionDos = '';
            }
            this.dialogContacto = true;
        },
        /**
         * Guarda los contactos del prestador.
         * Limpia el campo de numeroContacto y actualiza el modelo si el formulario es válido.
         * Cierra el diálogo de contacto.
         */
        guardarContacto() {
            this.registrarPrestador.numeroContacto = '';
            if (this.$refs.formularioContacto.validate()) {
                // Construir la dirección con los componentes ingresados
                const dir = `${this.numeroContactoUno != '' && this.extensionUno != '' ? this.numeroContactoUno + " EXT: " + this.extensionUno : this.numeroContactoUno} ${this.numeroContactoDos != '' ? " - " + this.numeroContactoDos : ''} ${this.numeroContactoDos != '' && this.extensionDos != '' ? " EXT: " + this.extensionDos : ''}`;
                // Actualizar el modelo con la nueva dirección
                this.registrarPrestador.numeroContacto = dir;
                this.dialogContacto = false;
            } 
            this.resetContacto();
        },
        /**
         * Guarda la información de un nuevo prestador en el servidor.
         * Crea un objeto con los datos del prestador, incluyendo la fecha de creación actual,
         * y realiza una solicitud al servidor para registrar el prestador. Luego, actualiza la lista
         * de los prestadores y restablece el formulario.
         */
        async guardarPrestador() {
            this.desactivar = true;
            const datos = {
                idPrestador: null,
                idEmpresa: this.enterprise.code,
                codigoHabilitacion: this.registrarPrestador.codigoHabilitacion,
                nombrePrestador: this.registrarPrestador.nombrePrestador,
                nit: this.registrarPrestador.nit,
                detalleCliente: this.registrarPrestador.detalleCliente,
                direccion: this.registrarPrestador.direccion,
                numeroContacto: this.registrarPrestador.numeroContacto,
                email: this.registrarPrestador.email,
                nivel: this.registrarPrestador.nivel,
                naturaleza: this.registrarPrestador.naturaleza,
                idDepartamento: this.registrarPrestador.departamento,
                idCiudad: this.registrarPrestador.municipio,
                eliminado: this.eliminado,
                usuario: this.usuario,
            }
            this.$http.post("msa-administration/prestador/crear", datos)
            .then(() => {
                this.listarPrestadores();
                this.reset();
                this.onPaisChange();
            })
            .catch((error) => {
                console.log(error);
            })
        },
        /**
         * Abre el diálogo para cambiar el estado de un registro.
         * Establece la propiedad 'dialogoCambiarEstado' en true para mostrar el diálogo.
         * Guarda el registro seleccionado en la propiedad 'prestadorSeleccionado'.
         * Este método se invoca cuando se desea cambiar el estado de un registro.
         * @param {object} item - El registro seleccionado.
         */
        abrirDialogoEstado(item) {
            this.dialogoCambiarEstado = true;
            this.prestadorSeleccionado = item;
            this.mensajeEstado = this.prestadorSeleccionado.eliminado === false ? '¿Desea inactivar el prestador?' : '¿Desea activar el prestador?';
        },
        /**
         * Cambia el estado de un registro.
         * Actualiza el estado del registro seleccionado ('prestadorSeleccionado') según la lógica definida.
         * Realiza una solicitud HTTP PUT al servidor para actualizar el estado del registro.
         * Después de la actualización exitosa, se registra el mensaje y se actualiza la lista de registros.
         */
        cambiarEstado() {
            this.desactivar = true;
            const horaActual = new Date;
            this.prestadorSeleccionado.eliminado = (this.prestadorSeleccionado.eliminado == false) ? true : false;
            const cambiarEstado = {
                idPrestador: this.prestadorSeleccionado.idPrestador,
                codigoHabilitacion: this.prestadorSeleccionado.codigoHabilitacion,
                idEmpresa: this.enterprise.code,
                eliminado: this.prestadorSeleccionado.eliminado,
                usuario: this.usuario,
                fecha: horaActual.toISOString(),
            }
            this.$http
                .put(`msa-administration/prestador/cambiarEstado`, cambiarEstado)
                .then(() => {
                    this.listarPrestadores();
                    this.dialogoCambiarEstado = false;
                    this.prestadorSeleccionado = {};
                }).catch(error => {
                    console.log(error);
                });
        },
        /**
         * Prepara el formulario de edición con los datos del prestador seleccionado y muestra la interfaz
         * para la actualización de datos del prestador.
         * @param item Los datos del prestador seleccionada.
         */
        async editarPrestador(item) {
            // Copiar los datos del prestador seleccionado al formulario de edición
            this.registrarPrestador = { ...item };
            this.prestadorSeleccionado = item;
            await this.listarPais();
            let paisActual = this.opcionesPais.find(pais => pais.value === this.prestadorSeleccionado.departamento.pais.idPais);
            if (typeof paisActual === 'object') {
                this.registrarPrestador.pais = paisActual.value;
            } else {
                this.registrarPrestador.pais = '';
            }
            await this.listarDepartamento();
            let departamentoActual = this.opcionesDepartamento.find(departamento => departamento.value === this.prestadorSeleccionado.departamento.idDepartamento);
            if (typeof departamentoActual === 'object') {
                this.registrarPrestador.departamento = departamentoActual.value;
            } else {
                this.registrarPrestador.departamento = '';
            }
            await this.listarMunicipio();
            let municipioActual = this.opcionesMunicipio.find(municipio => municipio.value === this.prestadorSeleccionado.ciudad.idCiudad);
            if (typeof municipioActual === 'object') {
                this.registrarPrestador.municipio = municipioActual.value;
            } else {
                this.registrarPrestador.municipio = '';
            }
            this.tituloDialog = 'Editar prestador';
            this.textoBoton = 'ACTUALIZAR';
            this.dialog = true;
            this.editar = true;
            this.disabled = 1;
        },
        /**
         * Actualiza la información de un prestador en el servidor con los datos del formulario de edición.
         * Crea un objeto con los datos actualizados del prestador y realiza una solicitud al servidor para
         * actualizar el prestador. Luego, actualiza la lista de los prestadores y restablece el formulario.
         */
        async actualizarPrestador() {
            const datos = {
                idPrestador: this.prestadorSeleccionado.idPrestador,
                idEmpresa: this.enterprise.code,
                codigoHabilitacion: this.prestadorSeleccionado.codigoHabilitacion,
                nombrePrestador: this.registrarPrestador.nombrePrestador,
                nit: this.registrarPrestador.nit,
                detalleCliente: this.registrarPrestador.detalleCliente,
                direccion: this.registrarPrestador.direccion,
                numeroContacto: this.registrarPrestador.numeroContacto,
                email: this.registrarPrestador.email,
                eliminado: this.eliminado,
                usuario: this.usuario,
            }
            this.$http.put("msa-administration/prestador/actualizar", datos)
            .then(() => {
                this.listarPrestadores();
                this.reset();
                this.onPaisChange();
            })
            .catch((error) => {
                console.log(error);
            })
        },
        /**
         * Restablece los valores de los campos y las variables relacionadas al prestador.
         * Establece los valores predeterminados y cierra los cuadros de diálogo abiertos.
         * Reinicia el estado de edición y activación de campos.
         * Reinicia el estado del componente de observación (refs.observer).
         */
        reset() {
            this.dialog = false;
            this.registrarPrestador.codigoHabilitacion = '';
            this.registrarPrestador.nombrePrestador = '';
            this.registrarPrestador.detalleCliente = '';
            this.registrarPrestador.nit = '';
            this.registrarPrestador.direccion = '';
            this.registrarPrestador.numeroContacto = '';
            this.registrarPrestador.email = '';
            this.registrarPrestador.nivel = '';
            this.registrarPrestador.naturaleza = '';
            this.registrarPrestador.pais = '';
            this.registrarPrestador.departamento = '';
            this.registrarPrestador.municipio = '';
            this.departamentoPresente = '';
            this.textoBoton = 'GUARDAR';
            this.tituloDialog = 'Crear prestador';
            this.editar = false;
            this.prestadorSeleccionado = {};
            this.disabled = 0;
            this.tipoVia = '';
            this.tipoVereda = '';
            this.nombreVereda = '';
            this.numVia = '';
            this.letraNum = '';
            this.tipoOrientacionVia = '';
            this.tipoOrientacion = '';
            this.informacionAdicional = '';
            this.cuadrante = '';
            this.barrio = '';
            this.dialogDireccion = false;
            this.tiposDireccion = 'urbana';
            this.verInformacionAdicional = false;
            this.verBarrio = false;
            this.verDireccion = false;
            this.verOrientacionVia = false;
            this.verletraNum = false;
            this.direccionUrbana = true;
            this.direccionRural = false;
            this.direccionModal = false;
            this.dialogDetalles = false;
            this.pais = '';
            this.departamento = '';
            this.municipio = '';
            this.dialogContacto = false;
            this.numeroContactoUno = '';
            this.numeroContactoDos = '';
            this.extensionUno = '';
            this.extensionDos = '';
            this.$refs.observer.reset();
            this.desactivar = false;
        },     
        /**
         * Restablece los valores de los campos y las variables relacionadas a la dirección del prestador.
         * Establece los valores predeterminados y cierra los cuadros de diálogo abiertos.
         * Reinicia el estado del componente de observación (refs.observerDireccionRural) y (refs.observerDireccionUrbana).
         */
        resetDireccion(){
            this.tipoVia = '';
            this.tipoVereda = '';
            this.nombreVereda = '';
            this.numVia = '';
            this.letraNum = '';
            this.tipoOrientacionVia = '';
            this.tipoOrientacion = '';
            this.informacionAdicional = '';
            this.cuadrante = '';
            this.barrio = '';
            this.dialogDireccion = false;
            this.tiposDireccion = '';
            this.verDireccion = false;
            this.verletraNum = false;
            this.verInformacionAdicional = false;
            this.verBarrio = false;
            this.verOrientacionVia = false;
            this.direccionUrbana = false;
            this.direccionRural = false;
            this.direccionModal = false;
            this.$refs.observerDireccionRural.reset();
            this.$refs.observerDireccionUrbana.reset();
        },
        /**
         * Restablece los valores de los campos y las variables relacionadas al contacto del prestador.
         * Establece los valores predeterminados y cierra los cuadros de diálogo abiertos.
         * Reinicia el estado del componente de observación (refs.observerContacto).
         */
        resetContacto(){
            this.dialogContacto = false;
            this.numeroContactoUno = '';
            this.numeroContactoDos = '';
            this.extensionUno = '';
            this.extensionDos = '';
            this.$refs.observerContacto.reset();
        },
        /**
         * Muestra los detalles de un prestador específico en un cuadro de diálogo.
         * Copia los datos del prestador seleccionado al objeto "prestadorSeleccionado" y abre el cuadro de diálogo de detalles.
         * @param {Object} item - El prestador del cual se mostrarán los detalles.
         */
        detallesDelPrestador(item) {
            this.registrarPrestador = { ...item };
            this.prestadorSeleccionado = item;
            this.pais = this.prestadorSeleccionado.departamento.pais.pais;
            if (this.prestadorSeleccionado.departamento.departamento !== 'NO APLICA') {
                this.departamento = this.prestadorSeleccionado.departamento.departamento;
            } else {
                this.departamento = '';
            }
            if (this.prestadorSeleccionado.ciudad !== null) {
                this.municipio = this.prestadorSeleccionado.ciudad.ciudad;
            } else {
                this.municipio = '';
            }
            this.dialogDetalles = true;
        },
    },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.fondoDialog {
  background-color: #1867c0;
  color: white;
}
::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}
::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}
::v-deep .elevation .v-data-footer {
  width: 100%;
}
::v-deep .elevation .v-data-footer__select .v-select {
  margin: 5px;
  margin-left: 10px;
}
.invisible-radio {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
.letra {
    color: black;
}
</style>